export interface User {
  uid: string
  name: string
  email: string
  termsAccepted: boolean
  photoURL?: string
  subscriptionPlan?: string
  subscriptionState?: string
  subscriptionBillingCycleAnchor?: string
}

export interface Creator extends User {
  createdAt: Date
  updatedAt: Date
  id: string
  termsAccepted: boolean
}

export const getCreatorFromUser = (user: User): Creator => ({
  ...user,
  id: user.uid,
  createdAt: (user as any).createdAt ? (user as any).createdAt : new Date(),
  updatedAt: new Date(),
  termsAccepted: user.termsAccepted,
  subscriptionPlan: user.subscriptionPlan || null,
  subscriptionState: user.subscriptionState || null,
  subscriptionBillingCycleAnchor: user.subscriptionBillingCycleAnchor || null,
})

export const EXPERIMENTAL = "experimental"
