export interface Conversation {
  id: string
  createdAt: Date
  updatedAt: Date
  userId: string
  userName: string | null
  userEmail: string | null
  userPhotoUrl: string | null
  creatorId: string | null
  agentId: string
  endedAt: Date | null
  startedAt: Date
  rating: number | null
  feedback: string | null
  feedbackTopic: string | null
  leftAt: Date | null
}

export enum ConversationStatus {
  Completed = "Completed",
  Live = "Live",
  UserLeft = "User Left",
}
