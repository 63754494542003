import { onAuthStateChanged } from "firebase/auth"
import { useEffect, useState } from "react"
import { clearPersistedState, useAppDispatch } from "../redux"
import { AppActions } from "../redux/appSlice"
import {
  createCreator,
  getCreator,
  getFeedbacks,
  isUserAdmin,
} from "../services"
import { getCreatorFromUser } from "../types"
import {
  firebaseAuth,
  getEntitiesWithFeedback,
  resetMixpanel,
  setUserOnMixpanel,
} from "../utils"

export function useFirebaseAuth() {
  const [loading, setLoading] = useState<boolean>(true)
  const dispatch = useAppDispatch()

  useEffect(() => {
    let mounted = true

    const unsubscribe = onAuthStateChanged(firebaseAuth, async (user) => {
      if (!mounted) return

      if (user) {
        if (!user.emailVerified) return

        setUserOnMixpanel(user)

        try {
          const creator = await getCreator(user.uid)
          if (mounted) {
            dispatch(
              AppActions.updateUser({
                uid: user.uid,
                name: user.displayName || "",
                email: user.email,
                photoURL: user.photoURL,
                subscriptionPlan: creator.subscriptionPlan,
                subscriptionState: creator.subscriptionState,
                subscriptionBillingCycleAnchor:
                  creator.subscriptionBillingCycleAnchor,
                termsAccepted: creator.termsAccepted ? true : false,
              }),
            )
          }

          const isAdmin = await isUserAdmin()
          if (mounted) {
            dispatch(AppActions.updateIsUserAdmin(isAdmin))
            setLoading(false)
          }
        } catch (error) {
          console.error("Error fetching creator:", error)
          if (!mounted) return

          const isNewUser =
            user.metadata.creationTime === user.metadata.lastSignInTime
          if (isNewUser) {
            const creator = await createCreator(
              getCreatorFromUser({
                email: user.email,
                name: user.displayName || "",
                uid: user.uid,
                termsAccepted: false,
              }),
            )
            dispatch(
              AppActions.updateUser({
                uid: user.uid,
                name: user.displayName || "",
                email: user.email,
                termsAccepted: creator.termsAccepted ? true : false,
              }),
            )
          }
        }

        const isAdmin = await isUserAdmin()
        dispatch(AppActions.updateIsUserAdmin(isAdmin))

        const feedbacks = await getFeedbacks({ last_id: "", limit: 1000 })
        const entities = getEntitiesWithFeedback(feedbacks)
        dispatch(AppActions.updateEntitiesWithFeedback(entities))

        setLoading(false)
      } else {
        if (mounted) {
          dispatch(AppActions.resetState())
          clearPersistedState()
          resetMixpanel()
          setLoading(false)
        }
      }
    })

    return () => {
      mounted = false
      unsubscribe()
    }
  }, [dispatch])

  return [loading]
}
