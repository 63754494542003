export enum SubscriptionPlan {
  FREE = "Free",
  PERSONAL = "Personal",
  PRO = "Pro",
  BUSINESS = "Business",
  ENTERPRISE = "Enterprise",
}

export enum SubscriptionState {
  ACTIVE = "active",
  CANCELED = "canceled",
  INCOMPLETE = "incomplete",
  INCOMPLETE_EXPIRED = "incomplete_expired",
  PAST_DUE = "past_due",
  UNPAID = "unpaid",
  PAUSED = "paused",
  EXPIRED = "expired",
  TRIALING = "trialing",
}
