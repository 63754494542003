import { useCallback, useEffect, useState } from "react"
import { getStripeProducts } from "../services"
import { StripeProduct } from "../types"

/**
 * Custom hook for fetching and managing Stripe products
 * @returns Object containing stripe products, loading state, error state, and refetch function
 */
export function useStripeProducts() {
  const [stripeProducts, setStripeProducts] = useState<
    Record<string, StripeProduct>
  >({})
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)

  const fetchProducts = useCallback(async () => {
    try {
      setLoading(true)
      setError(null)
      const products = await getStripeProducts()
      setStripeProducts(products)
    } catch (error) {
      console.error("Stripe Products Error:", {
        message: error?.message,
        stack: error?.stack,
        error: JSON.stringify(error, null, 2),
      })
      setError(error?.message || "Failed to fetch products")
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchProducts()
  }, [fetchProducts])

  return { stripeProducts, loading, error, refetch: fetchProducts }
}
