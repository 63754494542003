import { BadgeColor, BadgeProps } from "@beydesign/storybook"
import { FirebaseError } from "firebase/app"
import { DefaultAgentCreatorId } from "../constants/common"
import { useAppSelector } from "../redux"
import {
  Agent,
  AgentScreen,
  Avatar,
  CheckoutData,
  Conversation,
  ConversationStatus,
  Feedback,
  FeedbackEntity,
  LeadList,
  ModuleName,
  OutreachCampaignScreen,
  OutreachCampaignTemplate,
  Screen,
  SubscriptionPlan,
  VideoScreen,
} from "../types"
import { UsageDetails } from "../types/Usage"

export const isLocalDev = process.env.NODE_ENV === "development"

export const isDev = isLocalDev || window.location.href.includes("dev")

export const isStaging = window.location.href.includes("staging")

export const delay = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const calculatePrice = (checkoutData: CheckoutData): number => {
  const normalAmount =
    Math.round(checkoutData.wordsCount * 0.01 * checkoutData.quantity * 100) /
    100
  return normalAmount < 0.5 ? 0.5 : normalAmount
}

export const getAvailableCredits = (usageDetails: UsageDetails): number => {
  if (!usageDetails) return 0
  const usedCredits = usageDetails.current_usage / 1000
  const totalCredits = usageDetails.usage_limit / 1000
  const remainingCredits = totalCredits - usedCredits
  return remainingCredits
}

export const getCampaignCharacterCount = (
  leadList: LeadList,
  campaignTemplate: OutreachCampaignTemplate,
): [number, string] => {
  const { text: template, variables } = campaignTemplate
  const { data } = leadList
  const result = data.map((e) => {
    let result = template
    variables.forEach((variable) => {
      result = result.replace(`{${variable}}`, e[variable])
    })
    return result
  })
  const count = result.join("").length
  const preview = result[0]
  return [count, preview]
}

type GetNavigationPaths = (moduleName: ModuleName) => {
  back: string
  next: string
  initial: string
}
export const getNavigationPaths: GetNavigationPaths = (moduleName) => {
  switch (moduleName) {
    case ModuleName.videos:
      return {
        back: `/videos`,
        next: `/${Screen.videos}/${VideoScreen.createVideoMessage}`,
        initial: "/videos",
      }
    case ModuleName.outreachCampaigns:
      return {
        back: `/${Screen.outreachCampaigns}`,
        next: `/${Screen.outreachCampaigns}/${OutreachCampaignScreen.createCampaignMessage}`,
        initial: `/${Screen.outreachCampaigns}`,
      }
    case ModuleName.agents:
      return {
        back: `/${Screen.myAgents}`,
        next: `/${Screen.myAgents}/${AgentScreen.agentsInfo}`,
        initial: `/${Screen.myAgents}`,
      }
    case ModuleName.home:
      return {
        back: `/`,
        next: `/${Screen.myAgents}/${AgentScreen.agentsInfo}`,
        initial: `/`,
      }
  }
}

export const getFirebaseErrorMessage = (error: FirebaseError): string => {
  switch (error.code) {
    case "auth/id-token-expired":
      return "Your session has expired. Please log in again."
    case "auth/id-token-revoked":
      return "Your session has been revoked. Please log in again."
    case "auth/email-already-in-use":
      return "Email already in use. Please use login instead."
    case "auth/invalid-credential":
      return "Invalid credentials. Please check your email and password."
    case "auth/invalid-password":
      return "Invalid password. Please check your password and try again."
    case "auth/too-many-requests":
      return "You have made too many requests. Please try again later."
    case "auth/invalid-verification-code":
      return "Invalid verification code. Please check your code and try again."
    case "auth/user-disabled":
      return "Your account has been disabled. Please contact support."
    case "auth/user-not-found":
      return "User not found. Please check your email and try again."
    case "auth/invalid-action-code":
      return "Your reset password link is invalid. Please request a new one."
    default:
      return "An error occurred. Please try again."
  }
}

export const getEntitiesWithFeedback = (
  feedbacks: Feedback[],
): Set<FeedbackEntity> => {
  const entities = feedbacks
    .map((feedback) => feedback.entityType)
    .filter((e) => e != null)
  return new Set(entities)
}

/**
 * Generate a URL for the agent chat with optional custom branding
 * @param agentId - The ID of the agent to embed
 * @param customBranding - Whether to use custom branding (default: false)
 * @returns URL for the agent chat
 */
export const getAgentChatUrl = (agentId: string, customBranding?: boolean) => {
  const baseUrl = isDev
    ? "https://dev.bey.chat"
    : isStaging
      ? "https://staging.bey.chat"
      : "https://bey.chat"

  const path = customBranding ? `/agent/${agentId}` : `/${agentId}`

  return `${baseUrl}${path}`
}

export const decodeMessage = (message: string): string => {
  try {
    let processedMessage = message.replace(/^["'](.*)["']$/, "$1")

    // Check if the message is already decoded by attempting to encode it
    const encoded = JSON.stringify(processedMessage)
    const decoded = JSON.parse(encoded)

    if (processedMessage.includes("\\u")) {
      return decoded.replace(/\\u[\dA-F]{4}/gi, (match) =>
        String.fromCharCode(parseInt(match.replace(/\\u/g, ""), 16)),
      )
    }

    return processedMessage
  } catch (error) {
    return message.replace(/^["'](.*)["']$/, "$1")
  }
}

export const isFreePlan = (): boolean => {
  const user = useAppSelector((state) => state.app.user)
  return user?.subscriptionPlan === SubscriptionPlan.FREE
}

export const isDefaultAgent = (agent: Agent) => {
  return agent.creatorId === DefaultAgentCreatorId
}

export const isDefaultAvatar = (avatar: Avatar) => {
  return avatar.creatorId === DefaultAgentCreatorId
}

export const getConversationStatus = (
  conversation: Conversation,
): ConversationStatus => {
  if (conversation.leftAt === null) {
    return ConversationStatus.Live
  } else if (conversation.endedAt !== null) {
    return ConversationStatus.Completed
  } else {
    return ConversationStatus.UserLeft
  }
}

export const getConversationStatusProps = (
  conversation: Conversation,
): BadgeProps => {
  const status = getConversationStatus(conversation)

  switch (status) {
    case ConversationStatus.Live:
      return {
        icon: "Dot",
        color: BadgeColor.Green,
        text: "Live",
        iconWeight: "fill",
      }
    case ConversationStatus.Completed:
      return { icon: "Check", color: BadgeColor.Violet, text: "Completed" }
    case ConversationStatus.UserLeft:
      return { icon: "SignOut", color: BadgeColor.Red, text: "User Left" }
  }
}
