import { Autocomplete, createFilterOptions, TextField } from "@mui/material"
import * as React from "react"
import { H3 } from "../components"
import { useAppSelector } from "../redux"
import {
  getInitialOutreachCampaignTemplate,
  OutreachCampaignTemplate,
} from "../types"

type ExtendedOutreachCampaignTemplate = OutreachCampaignTemplate & {
  inputValue?: string
}
const filter = createFilterOptions<ExtendedOutreachCampaignTemplate>()

export default function SelectCampaignTemplate({
  templates,
  value,
  newText,
  setValue,
  setNewText,
}: {
  templates: OutreachCampaignTemplate[]
  value: OutreachCampaignTemplate
  newText: string
  setValue: (value: OutreachCampaignTemplate) => void
  setNewText: (value: string) => void
}) {
  const user = useAppSelector((state) => state.app.user)

  return (
    <>
      <Autocomplete
        value={value}
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setValue(templates.find((e) => e.name === newValue))
          } else if (newValue && (newValue as any).inputValue) {
            setValue(
              getInitialOutreachCampaignTemplate(
                user,
                (newValue as any).inputValue,
                newText,
              ),
            )
          } else {
            setValue(newValue)
            if (newValue == null) {
              setNewText("")
            }
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)

          const { inputValue } = params
          const isExisting = options.some(
            (option) => inputValue === option.name,
          )
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              name: `Add "${inputValue}"`,
              inputValue,
            } as any)
          }

          return filtered
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id="free-solo-with-text-demo"
        options={templates}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option
          }
          if (option.name) {
            return option.name
          }
          return option.name
        }}
        renderOption={(props, option) => <li {...props}>{option.name}</li>}
        freeSolo
        renderInput={(params) => (
          <>
            <TextField {...params} label="Select template or create new" />
          </>
        )}
      />
      <H3 title="Message" />
      <TextField
        variant="outlined"
        disabled={value && value.text ? true : false}
        value={value && value.text ? value.text : newText}
        onChange={(e) =>
          value && value.text
            ? setValue({ ...value, text: e.target.value })
            : setNewText(e.target.value)
        }
        multiline
        rows={4}
      />
    </>
  )
}
