type FeedbackTopics = string[]
type FeedbackTitle = string

const appFeedbackTitle: FeedbackTitle =
  "How happy are you with using our product?"
const appFeedbackTopics: FeedbackTopics = [
  "Avatars",
  "Agents",
  "Subscription",
  "Usability",
]

const agentFeedbackTitle: FeedbackTitle =
  "How happy are you with the experience of creating an agent?"
const agentFeedbackTopics: FeedbackTopics = [
  "Avatar selection",
  "Instructions",
  "Time spent",
  "Agent",
  "Knowledge files",
]

const avatarFeedbackTitle: FeedbackTitle =
  "How happy are you with the experience of creating an avatar?"
const avatarFeedbackTopics: FeedbackTopics = [
  "Filming instructions",
  "Time spent",
  "Avatar quality",
  "Payment",
]

export const feedbackTopics = {
  appFeedbackTopics,
  agentFeedbackTopics,
  avatarFeedbackTopics,
}

export const feedbackTitles = {
  appFeedbackTitle,
  agentFeedbackTitle,
  avatarFeedbackTitle,
}
