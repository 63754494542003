import * as Sentry from "@sentry/react"
import { getAnalytics } from "firebase/analytics"
import { initializeApp } from "firebase/app"
import {
  browserLocalPersistence,
  signOut as firebaseSignOut,
  getAuth,
  onAuthStateChanged,
  setPersistence,
} from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { isDev, isLocalDev, isStaging } from "./general"

const firebaseConfig = isDev
  ? {
      apiKey: "AIzaSyC788lZ2gkp8HjPNDhpWNBHsLCVUGaOksY",
      authDomain: "app-dev.bey.chat",
      projectId: "bey-dev",
      storageBucket: "bey-dev.appspot.com",
      messagingSenderId: "347029147333",
      appId: "1:347029147333:web:8c9c85b285179c0be4eaac",
      measurementId: "G-NBGKVM0BES",
    }
  : isStaging
    ? {
        apiKey: "AIzaSyC9TAzKVcWIruehblFd-4gI7MpPyFM2I7Q",
        authDomain: "app-staging.bey.chat",
        projectId: "bey-staging",
        storageBucket: "bey-staging.firebasestorage.app",
        messagingSenderId: "418945392850",
        appId: "1:418945392850:web:5e7c4d06e9ffaf5bbb8d0b",
        measurementId: "G-2CPNB48FMS",
      }
    : {
        apiKey: "AIzaSyCx2GDcIjQQ9nTtIbHHf6wGwvnp_2V7jX4",
        authDomain: "app.bey.chat",
        projectId: "bey-prod",
        storageBucket: "bey-prod.appspot.com",
        messagingSenderId: "352012720018",
        appId: "1:352012720018:web:27a46e753a2e56983f534b",
        measurementId: "G-BN8E3VD31V",
      }

const firebaseApp = initializeApp(firebaseConfig)
export const firebaseAnalytics = getAnalytics(firebaseApp)
export const firebaseAuth = getAuth(firebaseApp)
export const firebaseDb = getFirestore(firebaseApp)
export const firebaseStorage = getStorage(firebaseApp)
firebaseAuth.useDeviceLanguage()

// Get cookie configuration based on environment
const getCookieConfig = () => {
  const baseDomain = ".bey.chat"
  if (isLocalDev) {
    return {
      name: "__session_local",
      domain: "localhost",
    }
  }
  if (isDev) {
    return {
      name: "__session_dev",
      domain: baseDomain,
    }
  }
  if (isStaging) {
    return {
      name: "__session_staging",
      domain: baseDomain,
    }
  }
  return {
    name: "__session",
    domain: baseDomain,
  }
}

const setSessionCookie = (token: string | null) => {
  const { name, domain } = getCookieConfig()
  if (token) {
    document.cookie = `${name}=${token}; domain=${domain}; path=/; secure; samesite=Strict; max-age=604800`
  } else {
    document.cookie = `${name}=; domain=${domain}; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT; secure; samesite=Strict`
  }
}

setPersistence(firebaseAuth, browserLocalPersistence).catch((error) => {
  Sentry.captureException(error)
  console.error("Error setting auth persistence:", error)
})

export const initializeAuthObserver = (callback?: (user: any) => void) => {
  return onAuthStateChanged(firebaseAuth, async (user) => {
    if (user) {
      try {
        const token = await user.getIdToken(true)
        setSessionCookie(token)
      } catch (error) {
        Sentry.captureException(error)
        console.error("Error getting user token:", error)
      }
    } else {
      setSessionCookie(null)
    }
    if (callback) callback(user)
  })
}

export const signOut = async (): Promise<{
  success: boolean
  error?: string
}> => {
  try {
    setSessionCookie(null)
    await firebaseSignOut(firebaseAuth)
    return { success: true }
  } catch (error) {
    Sentry.captureException(error)
    return {
      success: false,
      error: error instanceof Error ? error.message : "Unknown error",
    }
  }
}
