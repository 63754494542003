import { Box, Typography, useMediaQuery, useTheme } from "@mui/material"
import { CheckCircle } from "@phosphor-icons/react"
import React, { useCallback, useState } from "react"
import { useAppSelector } from "../redux"
import { createCustomerCheckout } from "../services"
import { Colors, StripeProduct } from "../types"
import { getCurrencySymbol } from "../utils"
import { MainButton } from "./buttons"

interface ProductBoxProps {
  product: StripeProduct
  sx?: React.CSSProperties
}

export function ProductBox({ product: productDetails, sx }: ProductBoxProps) {
  const [processing, setProcessing] = useState<boolean>(false)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const price = productDetails.prices?.[0]
  const recurring = price?.recurring

  const user = useAppSelector((state) => state.app.user)
  const currentPlan = user?.subscriptionPlan || "Free"

  const handleSettingsClick = useCallback(async () => {
    try {
      setProcessing(true)
      if (!recurring) {
        window.open(
          "https://calendly.com/awais-beyondpresence/sales_discovery_call",
          "_blank",
        )
      } else {
        const response = await createCustomerCheckout(
          price.id,
          window.location.href,
        )
        window.open(response.checkout_url, "_self")
      }
    } catch (error) {
      console.error("Failed to create checkout session:", error)
    } finally {
      setProcessing(false)
    }
  }, [user, price, recurring])

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: isMobile ? "100%" : "18%",
        height: "100%",
        padding: "1.5rem",
        backgroundColor: Colors.grey100,
        borderRadius: "0.5rem",
        border: `1px solid ${Colors.grey300}`,
        boxSizing: "border-box",
        ...sx,
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        flexWrap="wrap"
        sx={{ mb: "1rem" }}
      >
        <Typography sx={{ fontWeight: "bold" }} variant="h6">
          {productDetails.name}
        </Typography>
      </Box>

      <Typography
        sx={{
          color: Colors.grey800,
          mb: "1.5rem",
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          minHeight: "4.5em",
        }}
        variant="body2"
      >
        {productDetails.description}
      </Typography>

      <Box
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"flex-start"}
        alignItems={"center"}
        sx={{ mb: "1.5rem", minHeight: "3rem" }}
      >
        {recurring ? (
          <>
            <Typography sx={{ fontWeight: "bold" }} variant="h4">
              {getCurrencySymbol(price?.currency)}
              {price?.unit_amount / 100}
            </Typography>

            <Box sx={{ ml: "0.5rem" }}>
              <Typography
                sx={{ fontSize: "0.8rem", color: Colors.grey800 }}
                variant="body2"
              >
                per
              </Typography>
              <Typography
                sx={{ fontSize: "0.8rem", color: Colors.grey800 }}
                variant="body2"
              >
                month
              </Typography>
            </Box>
          </>
        ) : (
          <Typography
            sx={{ fontWeight: "bold", color: Colors.grey800 }}
            variant="body1"
          >
            Custom Pricing
          </Typography>
        )}
      </Box>

      <MainButton
        style={{
          width: "100%",
          marginBottom: "1.5rem",
        }}
        text={
          currentPlan === productDetails.name
            ? "Current Plan"
            : !recurring
              ? "Schedule a call"
              : "Subscribe"
        }
        disabled={currentPlan === productDetails.name}
        white={currentPlan === productDetails.name}
        onClick={handleSettingsClick}
        loading={processing}
      />

      <Box sx={{ flex: "1 0 auto" }}>
        <Typography variant="body2" sx={{ fontWeight: "bold", mb: "0.75rem" }}>
          This includes:
        </Typography>
        <Box>
          {productDetails.marketing_features.map((feature) => (
            <Box
              key={feature.name}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"flex-start"}
              mb={"0.5rem"}
            >
              <CheckCircle
                color={Colors.almostBlack}
                size={16}
                style={{ marginTop: "3px", flexShrink: 0 }}
              />
              <Typography sx={{ ml: "0.5rem" }} variant="body2">
                {feature.name}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
